<template>
  <div class="text-center w-100">
    <VMenu
      offset-y
      left
    >
      <template #activator="{ on }">
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="button-chose-form"
          class="mb-2"
          v-on="on"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-plus
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Добавить<br>событие
        </div>
      </template>
      <VList dense>
        <VListItem
          class="custom-menu-item"
          @click="$root.$emit('createEvent')"
        >
          <VListItemIcon>
            <VIcon
              size="19"
              color="tt-black"
            >
              fal fa-chart-pie
            </VIcon>
          </VListItemIcon>
          <VListItemContent>Добавить опрос</VListItemContent>
        </VListItem>
        <VListItem
          class="custom-menu-item"
          @click="$root.$emit('createPush')"
        >
          <VListItemIcon>
            <VIcon
              size="19"
              color="tt-black"
            >
              fal fa-comment-dots
            </VIcon>
          </VListItemIcon>
          <VListItemContent>Добавить пуш</VListItemContent>
        </VListItem>
        <VListItem
          class="custom-menu-item"
          @click="$root.$emit('createEmail')"
        >
          <VListItemIcon>
            <VIcon
              size="19"
              color="tt-black"
            >
              fal fa-envelope
            </VIcon>
          </VListItemIcon>
          <VListItemContent>Добавить рассылку</VListItemContent>
        </VListItem>
      </VList>
    </VMenu>
  </div>
</template>
<script>
export default {
  name: 'AppEventAsideRight',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
